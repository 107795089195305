<template>
    <div class="services_maincontainer ode_flex aic jcc">
        <div class="services_content ode_flex aic jcb">

            <div class="services_Blocks ode_flex aic jcc">
                <div class="BlocksContainer" >
                    <div class="servBlock ode_flex aic jcb fcol">
                        <div class="servBlockHeader">

                        </div>
                        <div class="servBlockBody">
                            <p class="p_servBlock">UI/UX Design</p>
                            <p class="desc_servBlock">We develop user-friendly designs with high usability.</p>
                        </div>
                    </div>
                    <div class="servBlock ode_flex aic jcb fcol">
                         <div class="servBlockHeader">

                        </div>
                        <div class="servBlockBody">
                            <p class="p_servBlock">Branding</p>
                            <p class="desc_servBlock">We develop user-friendly designs with high usability.</p>
                        </div>
                    </div>
                </div>
                <div class="BlocksContainer BlocksContainer2" >
                    <div class="servBlock ode_flex aic jcb fcol" >
                         <div class="servBlockHeader">

                        </div>
                        <div class="servBlockBody">
                            <p class="p_servBlock">Development</p>
                            <p class="desc_servBlock">We develop user-friendly designs with high usability.</p>
                        </div>
                    </div>
                    <div class="servBlock ode_flex aic jcb fcol">
                         <div class="servBlockHeader">

                        </div>
                        <div class="servBlockBody">
                            <p class="p_servBlock">Support</p>
                            <p class="desc_servBlock">We develop user-friendly designs with high usability.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sec_header">
                <div> 
                    <p class="p_secTitl"> The Best  Services <br /> ODE Provides Ever  </p> 
                </div>
                <div class="underTitle underTitlesecTitl"> 
                    <span>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed <br /> 
                        nonumy eirmod tempor invidunt ut labore et dolore.
                    </span>    
                </div>

                <div class="ButtonsContainer_Home">
                    <ODEButton />
                </div>
            </div>

        </div>
    </div>
</template>

<style>
    @import url("./OurServeces.css");
</style>

<script src="./OurServeces.js"></script>