<template>
    <div class="HomeSectionMainContainer">
        <div class="hs1">
            <!-- In -->
            <div class="hsecinfoteil">
                <div class="hitrtit">
                    <h2 class="ODE">ODE</h2>
                </div>
                <div> <h1 class="h1_Home">Development <br /> is our <span class="underLineEf">Goal Ever</span> </h1> </div>
                <div class="underTitle"> 
                    <span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed <br /> 
                        nonumy eirmod tempor invidunt ut labore et dolore.
                    </span>    
                </div>

                <div class="ButtonsContainer_Home">
                    <ODEButton />
                    <span class="spanLink pointer">Projects</span>
                </div>
                
            </div>

            <!-- SVG -->
            <div class="svgContainer">
                <div class="imgContainer">
                    <img class="svIm"  src="@/assets/imgs/bg.png" alt="image" />
                </div>
            </div>


            <div class="fussz ode_flex jcb aic">
                <div class="SocialmediaIconsContainer socialmediaicon pointer">
                    <i class="fa-brands fa-dribbble socialmediaicon"></i>
                    <i class="fa-brands fa-github socialmediaicon"></i>
                    <i class="fa-brands fa-linkedin socialmediaicon"></i>
                </div>
                <div class="fusszline"></div>
            </div>
        </div>

        
    </div>
</template>

<style >
@import url("./HomeSection.css");
</style>

<script src="./HomeSection.js"></script>