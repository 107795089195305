<template>
    <div class="body">
    <mainHeader />
    <HomeSection />
    <OurServeces />
    </div>
</template>

<script>
import mainHeader from "@/components/global/mainHeader/mainHeader.vue";
import HomeSection from "@/components/privet/Pagesections/HomeSection/HomeSection.vue";
import OurServeces from "@/components/privet/Pagesections/OurServeces/OurServeces.vue";

export default {
    name : "HomeView" ,
    components : {
        mainHeader ,
        HomeSection ,
        OurServeces
    }
}
</script>


<style >
    .body{
        width: 100%;
        height: max-content;
        overflow-x: hidden;
    }
</style>