 const app = () =>{
    const MenuBtn = document.getElementById("MenuBtn");
    const MenuBtnL1 = document.getElementById("MenuBtnL1");
    const MenuBtnL2 = document.getElementById("MenuBtnL2");
    const Drawer = document.getElementById("Drawer");
    const DrawerBackground = document.getElementById("DrawerBackground");
    MenuBtn.onclick = function () {
        DrawerHandler();
      }
      DrawerBackground.onclick = function () {
          DrawerHandler();
      }
    const DrawerHandler = () =>{
        MenuBtn.classList.toggle("MenuIconContainerActive");
        MenuBtnL1.classList.toggle("MenuIconUi1Active");
        MenuBtnL2.classList.toggle("MenuIconUi2Active");
        Drawer.classList.toggle("DrawerActive");
        DrawerBackground.classList.toggle("DrawerBackgroundActive");
    }
 }


export default {
    name : "mainHeader" , 
    methods : {

    },
    mounted() {
        document.onreadystatechange = () => { 
          if (document.readyState == "complete") { 
            app()
          } 
        }
    }
}