<template>
 <header class="ODE_HeaderContainer ode_flex jcb aic">
        <div class="LogoContainer">
            <img src="@/assets/icons/Logo.png" alt="Logo" class="Logo" @click="DrawerHandler">
        </div>

        <div class="DrawerBackground" id="DrawerBackground"></div>
        <div class="Drawer" id="Drawer">
                Test
        </div>

        <nav class="NavContainer ode_flex aic">
            <div class="NavSection borderNone ode_flex aic">
                <p class="NavItem P1">Home</p>
                <p class="NavItem P1">Projects</p>
                <p class="NavItem P1">Components</p>
                <p class="NavItem P1">Contact</p>
                <p class="NavItem P1">Blog</p>
            </div>

            <div class="NavAvtions ode_flex aic">
                <div class="NavSection ode_flex aic">
                    <i class="fa-solid fa-language NavItem NavIcons"></i>
                    <i class="fa-solid fa-circle-half-stroke NavItem NavIcons"></i>
                </div>

                <div class="NavSection LastNavSection ode_flex">
                    <p class="NavItem P1">Login</p>
                    <div class="NavItem HeaderButton ode_flex jcc aic font">Register</div>
                </div>
            </div>

            <div class="ode_flex jcb aie fcol MenuIconContainer pointer" id="MenuBtn">
                <div class="MenuIconUi1" id="MenuBtnL1"></div>
                <div class="MenuIconUi2" id="MenuBtnL2"></div>
            </div>
        </nav>
        
    </header>

</template>



<script src="./mainHeader.js"></script>
<style scoped>
@import url("./mainHeader.css");
</style>
