<template>
  <router-view />
</template>

<style>
  @import url("./GlobalStyle/main.css");
  @import url("./GlobalStyle/colors.css");
  @import url("./GlobalStyle/ode_flex/flex.css");
  @import url("./responsiveStyle/responsive.css");
  @import url("./normalize/normalize.css");
</style>
