<template>
    <div class="ODE_Button_Container" @mousemove="effectonMove" @mouseleave="MouseLeave">
        <div class="ButtonContent" id="ButtonContent">
            <span class="ButtonSpan">Let’s Talk</span>
            <i class="fa-solid fa-arrow-right arrowIcon"></i>
        </div>
    </div>
</template>


<style scoped>
    .ODE_Button_Container{
        width: 143px;
        height: 50px;
        color: #000;
        background-color: var(--primary1);
        box-shadow: var(--shadow);
        text-align: center;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: #fff;
        font-size: 15px;
        font-family: var(--mainFont);
        cursor: pointer;
        position: relative;
        transition:  0.15s;
        transform-style: preserve-3d;
    }
    .ButtonContent{
        position: absolute;
        transition:  0.2s;
    }
    .ButtonSpan{
        margin-right: 10px;
    }
    .arrowIcon{
        font-size: 14px;
    }
</style>

<script>

var executed = false;
const effectonMove = (e) => {
    const element = e.currentTarget;
    let ClientRect = element.getBoundingClientRect();
    let top = ClientRect.top;
    let left = ClientRect.left;
    let height = ClientRect.height;
    let width = ClientRect.width;

    let mouse_x = e.clientX - left;
    let mouse_y = e.clientY  - top;
    let Vertical = height / 2 - mouse_y;
    let Horizontal = width / 2 - mouse_x;

    const ButtonContent = element.children[0];
    ButtonContent.style.transform = `translate(${-Horizontal/14}px , ${-Vertical/12}px )`;
    element.style.transform = `translate(${-Horizontal/13}px , ${-Vertical/15}px )`;

    if (!executed) {
        executed = true;
        setTimeout(() => {
            ButtonContent.style.transition = `0s`;
            element.style.transition = `0s`;
            console.log("s");
        }, 100);
    }
}

const MouseLeave = (e) => {
    const element = e.currentTarget;
    const ButtonContent = element.children[0];
    element.style.transition = `0.3s`;
    ButtonContent.style.transition = `0.2s`;
    setTimeout(() => {
        ButtonContent.style.transform = `translate(0px , 0px)`;
        element.style.transform = `translate(0px , 0px)`;
    }, 10);
    executed = false;
    setTimeout(() => {
        element.style.transition = `0.3s`;
        ButtonContent.style.transition = `0.2s`;
    }, 105);
}


export default {
    name : "ODEButton",
    methods: {
        effectonMove ,
        MouseLeave
    }
}
</script>
