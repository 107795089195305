import ODEButton from "@/components/global/Button/Button.vue";
export default {
    name : "HomeSection" ,
    components : {
        ODEButton
    }
}


window.addEventListener('wheel', function(event)
{
 if (event.deltaY < 0)
 {
  console.log('scrolling up');
 }
 else if (event.deltaY > 0)
 {
  console.log('scrolling down');
 }
});
